<template>
    <input
        v-model="search"
        type="text"
        name="search"
        class="form-control form-control-lg w-full mb-4"
        :placeholder="placeholder"
        autocomplete="off"
        @input="onSearch"
    />
</template>

<script>
let searchTimer = null;

export default {
    name: 'ContentSearch',
    props: {
        placeholder: {
            type: String,
            default: 'Поиск',
        },
        queryKey: {
            type: String,
            default: 'query',
        },
    },
    data() {
        return {
            search: null,
        };
    },
    mounted() {
        this.fillSearchOnInit();
    },
    beforeUnmount() {
        searchTimer = null;
    },
    methods: {
        onSearch() {
            clearTimeout(searchTimer);
            // TODO очему это сделано так? Это должно было вызывать событие наверх!
            const timer = setTimeout(() => {
                this.$router.push({
                    query: { [this.queryKey]: this.search },
                });
            }, 800);

            searchTimer = timer;
        },
        onEnter(e) {
            if (e.keyCode === 13) {
                this.$router.push({
                    query: { [this.queryKey]: this.search },
                });
            }
        },
        fillSearchOnInit() {
            const query = this.$route.query;
            if (query && query[this.queryKey]) {
                this.search = this.$route.query[this.queryKey];
            }
        },
    },
};
</script>

<style></style>
