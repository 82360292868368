import { serialize } from '@/utils/object-to-form-data';

export const errorResponse = {
    methods: {
        errorResponse(ex) {
            this.loading = false;
            console.log('cant send request: ' + ex);
            let errorText = '';

            if (ex?.response?.data && ex?.response?.status === 400) {
                for (const key in ex.response.data.message) {
                    this.form[key] = null;
                }
                errorText = ex.response.data.message ? ex.response.data.message : 'Введенные данные неверные';
            } else if (ex?.response?.data?.errors) {
                Object.values(ex.response.data.errors).forEach((error) => {
                    this.$notify({
                        title: 'Ошибка',
                        text: error,
                        type: 'error',
                    });
                });
            } else if (ex?.response?.data) {
                errorText =
                    ex.response.data && !!ex.response.data.message ? JSON.stringify(ex.response.data.message) : '';
            } else {
                errorText = 'Ошибка в коде';
            }

            this.$notify({
                title: 'Ошибка',
                text: errorText,
                type: 'error',
            });
        },
    },
};

export const formPreparation = {
    methods: {
        /**
         * @returns {FormData}
         * */
        formPreparation(options = {}) {
            const form = new FormData();
            const defaultOptions = {
                /**
                 * добавляет индексы массивам
                 * defaults to false
                 */
                indices: true,

                /**
                 * treat null values like undefined values and ignore them
                 * defaults to false
                 */
                nullsAsUndefineds: true,

                /**
                 * convert true or false to 1 or 0 respectively
                 * defaults to false
                 */
                booleansAsIntegers: true,

                /**
                 * store arrays even if they're empty
                 * defaults to false
                 */
                allowEmptyArrays: false,
            };

            const mergedOptions = Object.assign(defaultOptions, options);

            const formDataFormatted = serialize(this.form, mergedOptions, form);

            return formDataFormatted;
        },
    },
};
